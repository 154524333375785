// Import Sass mixins, variables, Compass modules, etc.
@import "_base.scss";

/**
 * @file
 * Print styling
 *
 * We provide some sane print styling for Drupal using Zen's layout method.
 */


@media print {

  a:link,
  a:visited { /* underline all links */
    text-decoration: underline !important;

    #site-name & { /* Don't underline header */
      text-decoration: none !important;
    }
  }

  #content {
    a[href]:after {
      display: none;
    }

    a[href^="javascript:"]:after,
    a[href^="#"]:after { /* Only display useful links. */
      content: "";
    }
  }

  #content { /* Un-float the content */
    float: none !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  body,
  #page,
  #main,
  #content { /* Turn off any background colors or images */
    color: #000;
    background-color: transparent !important;
    background-image: none !important;
  }

  #skip-link,
  #toolbar,
  #navigation,
  .region-sidebar-first,
  .region-sidebar-second,
  #footer,
  .breadcrumb,
  .tabs,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .pager,
  .feed-icons,
  .secondary-menu,
  #cc-nav-search,
  .funding-credit,
  .partner-aft,
  .views-classroom-strategy-button-block,
  .views-print-button-block,
  .views-bookfinder-button-block,
  .view-filters,
  .messages { /* Hide sidebars and nav elements */
    visibility: hidden;
    display: none;
  }

  #header {
    height: auto !important;
  }

  #logo {
    margin-top: 0;
    padding-top: 0;
  }

  .group-page-intro {
    .field-page-image {
      float: left;
      width: 25%;
      margin-right: 15px;
      margin-bottom: 15px;
    }

    .field-strategy-intro {
      width: 100%;
    }
  }

  h1 {
    font-size: 28px !important;
  }

  h3 {
    font-size: 16px !important;
  }

  h2,
  .field-label {
    font-size: 21px !important;
  }

  h4 {
    font-size: 14px !important;
  }

  p,
  a,
  li {
    font-size: 12px !important;
  }

  table {
    th {
      color: #00539B !important;
      font-size: 12px !important;
    }

    tr {
      td {
        font-size: 11px !important;
        color: #000000 !important;
      }
    }
  }

} /* End @media print */
